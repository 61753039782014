import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Icons
import { FiChevronUp } from "@react-icons/all-files/fi/FiChevronUp";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";

import { IoMdMale } from "@react-icons/all-files/io/IoMdMale";
import { IoMdFemale } from "@react-icons/all-files/io/IoMdFemale";

/**
 * Custom Icon Button Component
 *
 * @param {string} type - the type of button, directional (top, right, bottom, left) or gender (female/male)
 * @param {string} width - width of button, can be given in terms of string "40px" or number, 40px by default
 * @param {string} height - height of button, can be given in terms of string "40px" or number, 40px by default
 * @param {number} fontSize - font size of icon within the button, 2em by default
 * @param {string} bgColor - the background color of button, #f0862c by default
 * @param {string} fontColor - the text/icon color of button, #fff by default
 * @param {function} onClick - callback function when on click
 * @param {boolean} isDisabled - to determine if button is disabled, false by default, button turns grey and no click callbacks will be executed
 * @param {boolean} isInactive - to determine if button is inactive/unselected and opacity will drop, false by default
 * @param {boolean} isCircle - to determine if button is circular, true by default
 * @param {number} py - Vertical padding values of button, 0 by default
 * @param {number} px - Horizontal padding values of button, 0 by default
 * @param {number} my - Vertical margin values of button, 0 by default
 * @param {number} mx - Horizontal margin values of button, 0 by default
 */

const CustomIconButton = ({
  type,
  width,
  height,
  fontSize,
  bgColor,
  fontColor,
  onClick,
  isDisabled,
  isInactive,
  isCircle,
  className,
  py,
  px,
  mx,
  my,
}) => {
  const useStyles = makeStyles({
    button: {
      position: "relative",
      zIndex: 2,
      borderRadius: isCircle ? "50%" : "10px",
      backgroundColor: bgColor,
      border: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: height,
      width: width,
      color: fontColor,
      fontSize: fontSize,
      cursor: "pointer",

      // "&:hover": {
      //   backgroundColor: "#d66f14",
      // },
    },

    disabledButton: {
      backgroundColor: "#ddd",
    },

    inactiveButton: {
      opacity: 0.5,
    },
  });

  const classes = useStyles();
  let icon;

  switch (type) {
    case "top":
      icon = <FiChevronUp />;
      break;

    case "right":
      icon = <FiChevronRight />;
      break;

    case "bottom":
      icon = <FiChevronDown />;
      break;

    case "left":
      icon = <FiChevronLeft />;
      break;

    case "male":
      icon = <IoMdMale />;
      break;

    case "female":
      icon = <IoMdFemale />;
      break;
  }

  return (
    <Box
      py={py}
      px={px}
      my={my}
      mx={mx}
      className={`${className ? className : ""} ${classes.button} ${
        isDisabled ? classes.disabledButton : ""
      } ${isInactive ? classes.inactiveButton : ""}`}
      onClick={!isDisabled ? onClick : function () {}}
    >
      {icon}
    </Box>
  );
};

CustomIconButton.defaultProps = {
  type: "right",
  width: "50px",
  height: "50px",
  isDisabled: false,
  isInactive: false,
  isCircle: false,
  fontSize: "2.5em",
  bgColor: "#f0862c",
  fontColor: "#fff",
  py: 0,
  px: 0,
  my: 0,
  mx: 0,
};

export default CustomIconButton;
